<template>
  <div class="h-100 ob-ed">
    <div class="lp__language">
      <h5>{{ $t("landing_page.language.title") }}</h5>
      <b-img
        :src="require('@/assets/images/landing/language-illustration-bg.svg')"
        fluid
        alt="language illustration"
      ></b-img>
      <div class="ob-lang__content">
        <div class="ob-lang">
          <div
            v-for="l in allLanguage"
            :key="l.id"
            :class="{ 'ob-lang__div': true, active: l.id == locale }"
            @click="locale = l.id"
          >
            {{ l.name }}
            <img
              src="@/assets/images/landing/icons/tick-blue.svg"
              v-if="l.id == locale"
            />
          </div>
        </div>
      </div>
      <div class="ob-lang__btn">
        <Button
          variant="link"
          pill
          className="mr-3"
          @click="$bvModal.hide(COMMAN_MODAL_IDS.LANGUAGE_SETUP_MODAL)"
        >
          {{ $t("general.dismiss") }}</Button
        >
        <Button variant="primary-lighter" pill className="mr-3" @click="onSave">
          {{ $t("general.confirm") }}</Button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import userLanguageMixin from "@/core/mixins/userLanguage";

import COMMAN_MODAL_IDS from "@/components/Shared/CommonModalConstants";
import Button from "@/components/WrapperComponents/Button.vue";
export default {
  components: { Button },
  mixins: [userLanguageMixin],
  data() {
    this.$i18n.locale = "en";
    return {
      locale: "en",
      COMMAN_MODAL_IDS: {}
    };
  },
  computed: {
    ...mapGetters(["allLanguage", "language"])
  },
  mounted() {
    this.locale = this.language;
    this.COMMAN_MODAL_IDS = COMMAN_MODAL_IDS;
  },
  methods: {
    onSave() {
      this.$i18n.locale = this.locale;
      this.setUserLanguage(this.locale);
      this.$bvModal.hide(COMMAN_MODAL_IDS.LANGUAGE_SETUP_MODAL);
    }
  }
};
</script>
<style lang="scss" scoped>
.lp__language {
  h5 {
    text-align: center;
    margin-bottom: 16px;
  }
  .ob-lang__content {
    .ob-lang {
      text-align: center;
      margin: 16px 0;
      .ob-lang__div {
        border: 1px solid $brand-public-primary;
        border-radius: 4px;
        width: 48.5%;
        padding: 12px 16px;
        font-weight: 500;
        font-size: 18px;
        line-height: 26px;
        text-align: left;
        letter-spacing: 0.1px;
        color: $brand-public-primary;
        display: inline-flex;
        justify-content: space-between;
        margin: 0 2% 20px 0;
        cursor: pointer;
        &:nth-child(even) {
          margin-right: 0;
        }
        &.active {
          border: 2px solid $brand-primary-lighter;
          color: $brand-primary-lighter;
        }
      }
    }
  }
  .ob-lang__btn {
    @include flex-horizontal-center;
    margin-bottom: 30px;
    .btn {
      text-transform: uppercase;
    }
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1150px) {
  .ob-lang__content {
    .ob-lang {
      .ob-lang__div {
        width: 210px;
      }
    }
  }
}
</style>
